import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogRoll = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
  }
`

const HeroText = styled.div`
  margin-right: 32px;
  flex: 1;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-right: 0;
  }
`

const HeroImage = styled.img`
  margin-bottom: 0px;
  max-width: 496px;
  max-height: 362px;
  padding: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`

const BlogIndex = () => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      avatar: file(absolutePath: { regex: "/raid-boss-mockup.png/" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      eeveelutions: file(
        absolutePath: { regex: "/Eevee Evolutions Pokémon GO.png/" }
      ) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      pokedex: file(absolutePath: { regex: "/Pokedex Pokemon GO.png/" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      raidboss: file(
        absolutePath: { regex: "/Current Raid Bosses - Pokemon GO.png/" }
      ) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      typechart: file(absolutePath: { regex: "/Pokémon GO Type Chart.png/" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout
      location={{ pathname: "/" }}
      title={"Tier list and counters for Pokémon GO"}
    >
      <SEO
        title="Raid Boss - Tier list, types and counters for Pokémon GO"
        slug={"/"}
      />

      <div
        style={{
          display: "flex",
          maxWidth: 1000,
          margin: "0 auto",
          minHeight: "80vh",
          alignItems: "center"
        }}
      >
        <HeroText>
          <h1 style={{ marginTop: 0 }}>
            Tier list and counters for Pokémon GO
          </h1>

          <h2>
            Get a list of the current raid bosses in Pokémon GO in the press of
            a finger
          </h2>

          <div>
            <Link
              to="/current-raid-bosses/"
              style={{
                textAlign: "center",
                textDecoration: "none",
                color: "white",
                fontWeight: "bold",
                display: "flex"
              }}
            >
              <div
                style={{
                  backgroundColor: "#fa5a5a",
                  padding: 20,
                  borderRadius: 12
                }}
              >
                Discover the current Raid Bosses
              </div>
            </Link>
          </div>
        </HeroText>

        <HeroImage
          src={data.avatar.childImageSharp.fluid.src}
          alt={"Pokemon Go Raid Boss App"}
        />
      </div>

      <div
        style={{
          maxWidth: 1000,
          margin: "0 auto",
          textAlign: "center"
        }}
      >
        <h2>All you need to know about raid bosses and Pokémon GO</h2>
        <BlogRoll>
          <div style={{ margin: 8 }}>
            <h3 style={{ flex: 1, textAlign: "center" }}>
              <Link to="/eevee-evolutions-pokemon-go/">Eevee Evolutions</Link>
            </h3>
            <Link to="/eevee-evolutions-pokemon-go/">
              <img
                src={data.eeveelutions.childImageSharp.fluid.src}
                alt="Eevee Evolutions Pokémon GO"
              />
            </Link>
            <p>
              Eevee comes with 8 evolutions. In Pokémon GO there are only 7 of
              them. Did you catch them all yet? Learn about Eevee and its
              eeveelutions and more in our article!
            </p>
          </div>
          <div style={{ margin: 8 }}>
            <h3 style={{ flex: 1, textAlign: "center" }}>
              <Link to="/pokedex-pokemon-go/">Pokédex</Link>
            </h3>
            <Link to="/pokedex-pokemon-go/">
              <img src={data.pokedex.childImageSharp.fluid.src} alt="Pokédex" />
            </Link>
            <p>
              There are 18 types in Pokémon GO. Some Pokémons even have 2 types
              which makes around 324 combination. Discover all of them in our
              extensive Type Chart for Pokémon GO!
            </p>
          </div>
          <div style={{ margin: 8 }}>
            <h3 style={{ flex: 1, textAlign: "center" }}>
              <Link to="/pokemon-go-type-chart/">Type Chart</Link>
            </h3>
            <Link to="/pokemon-go-type-chart/">
              <img
                src={data.typechart.childImageSharp.fluid.src}
                alt="Pokédex"
              />
            </Link>

            <p>
              With more than 640 Pokémons, Pokémon GO has a large Pokédex, did
              you catch them all yet? How many are you missing? Get a complete
              list of all Pokémon with our Pokédex.
            </p>
          </div>
        </BlogRoll>
        <BlogRoll>
          <div style={{ margin: 8 }}>
            <h3 style={{ flex: 1, textAlign: "center" }}>
              <Link to="/current-raid-bosses/">Current Raid Bosses</Link>
            </h3>
            <Link to="/current-raid-bosses/">
              <img
                src={data.raidboss.childImageSharp.fluid.src}
                alt="Pokédex"
              />
            </Link>
            <p>
              Raid Bosses are an important feature of the Pokémon GO game. Do
              you know how to defeat every single one of them? All the
              informations you need to deafeat (and catch) them all.
            </p>
          </div>
          <div style={{ flex: 1 }} />
          <div style={{ flex: 1 }} />
        </BlogRoll>
      </div>
    </Layout>
  )
}

export default BlogIndex
